import React from 'react';

const header = () => {
  return (
    <header>
        <h1 className='logo'> <a href="index.html"> Azzey</a></h1>
        <nav>
            <ul>
                <li><a href="#comm">Community</a></li>
                <li><a href="#server">Server</a></li>
                <li><a href="#ons">Over</a></li>
                <li><a href="#">Shop</a></li>
            </ul>
        </nav>
    </header>    
  );
}
    export default header;
