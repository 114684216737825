import azzey from './img/azzey.png'

const wat = () => {
  return (
      <div className="hero" style={{ backgroundImage: `url(${azzey})` }}>
          <h2 className='section-title'>
              Wat is Azzey?
          </h2>
          <div className="wrapper">
            <p className='section-content'> <span className='span__blue'>Azzey</span> is het Youtube Kanaal van Jari van kaam. Azzey draait om <span className='span__blue'>Gaming, coding</span> en nog veel meer</p>
          </div>
      </div>
  );
}

export default wat;
