import React from 'react';

const footer = () => {
  return (
    <div className="wrapper">
        <footer className='footer'>
            <p className='footer-p'>Azertoria netwerk &copy; 2022</p>
        </footer> 
    </div>     
  );
}

export default footer;
