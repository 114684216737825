const server = () => {
  return (
    <div className='wrapper'>
        <h2 className="section-title" id="server">
            Azzey Minecraft server
        </h2>
        <div className="img-container">
           <p className="section-content"> 
                <ul className='server--info'>
                    <li>24/7 online</li>
                    <li>Ip: play.azertoria.nl</li>
                    <li>Minecraft Versie: 1.17.1</li>
                </ul>   
            </p> 
        </div>
    </div>
  );
}

export default server;
