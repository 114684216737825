import Header from './components/header';
import Hero from './components/Hero';
import Wat from './components/wat';
import Comm from './components/comm';
import Server from './components/server';
import Ons from './components/about'
import Footer from './components/footer'

function App() {
  return (
    <div className="container">
        <Header />
        <Wat />
        <div className='spacer'></div>
        <Comm />
        <div className='spacer'></div>
        <Server />
        <div className="spacer"></div>
        <Ons />
        <div className="spacer"></div>
        <Footer />

    </div>    
  );
}

export default App;
