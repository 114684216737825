import React from 'react';

const comm = () => {
  return (
    <div className='wrapper'>
      <div id='comm'>
          <h2 className="section-title">
              Azzey Community
          </h2>
          <div className="box">
              <ul>
                  <li><a href="">Youtube - Azertoria Network(azzey valt hier onder) </a></li>
                  <li><a href="">discord - Azzey en Azershub servers</a></li>
                  <li><a href="">minecraft - Azzey server</a></li>
                  <p className='section-content small'>Alle <span className="span__imp"> Azzey</span> merknamen vallen onder <span className="span__imp"> Azertoria Network</span></p>
              </ul>
          </div>
      </div>
    </div>
  );
}

export default comm;
