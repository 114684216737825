import React from 'react';

const about = () => {
  return (
    <div className='wrapper'>
    <div id='ons'>
        <h2 className="section-title">
           Over Team Azzey
        </h2>
        <div className="box primary">
            <p className="section-content small">
                Wij bij <span className="span__accent"> Azzey</span> zijn een beginnend team van YouTube content creators.
                Wij zijn een groep van 'volwassenen' die graag in de weer zijn met opnemen, video bewerking en gaming.
                Ons doel is het vermaken van de kijker en zelf plezier hebben tijdens ons YouTube avontuur.
            </p>
        </div>
    </div>
  </div>
  );
}

export default about;
